<script setup>
const blend = useBlend();
const animate = ref(false);

const visible = ref(blend.visible.value);

const dismiss = () => {
  blend.dismissBlend();
}

if (process.client) {
  watch(
      () => blend.visible.value,
      () => {
        if (blend.visible.value !== 'none') {
          document.body.classList.add('blend-' + blend.visible.value);
          document.documentElement.classList.add('blend-' + blend.visible.value);
          visible.value = blend.visible.value;
          setTimeout(() => animate.value = true, 100);
        } else {
          document.body.classList.remove('blend-mobile', 'blend-all','blend-tablet','blend-underlay');
          document.documentElement.classList.remove('blend-mobile', 'blend-all','blend-tablet','blend-underlay');

          animate.value = false;
          setTimeout(() => visible.value = 'none', 500);
        }
      },
      {immediate: true}
  )

  watch(
      () => blend.overlay.value,
      () => {
        if (!blend.overlay.value) {
          document.body.classList.add('blend-underlay');
        } else {
          document.body.classList.remove('blend-underlay');
        }
      },
      {immediate: true}
  )
}
</script>

<template>
  <div class="hidden fixed inset-0 bg-black/75 opacity-0 transition-opacity duration-500"
       @click="dismiss"
       @touchmove.passive.prevent=""
       :class="{'!block sm:!hidden': visible === 'mobile','!block md:!hidden': visible === 'tablet', '!block': visible === 'all', 'opacity-100': animate, 'z-50': blend.overlay.value, 'z-40': !blend.overlay.value}"></div>
</template>
